import { createDescriptorData } from "../thestoreCommands";
import logger from "../../Log";
import { normalizeToBoolean } from "../normalizeToBoolean";
export var commandSetMousePointerLock = function (configData, messageJson, setUsePointerLock) {
    var _a;
    var lockValue = normalizeToBoolean((_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.value);
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            status: messageJson.command,
            value: lockValue,
        },
    };
    setUsePointerLock(lockValue);
    logger.debug("Use pointer lock value set to: ".concat(lockValue));
    if (lockValue) {
        var element = document.querySelector("video");
        if (element instanceof HTMLVideoElement) {
            element.requestPointerLock();
            logger.debug("Pointer lock was started.");
        }
        else {
            logger.warn("No valid <video> element found.");
        }
    }
    else {
        document.exitPointerLock();
        logger.debug("Pointer lock was ended.");
    }
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
