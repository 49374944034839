import { createDescriptorData } from "../thestoreCommands";
import logger from "../../Log";
export var commandPointerLockEnd = function (configData, messageJson) {
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            status: messageJson.command,
        },
    };
    document.exitPointerLock();
    logger.debug("Pointer lock was ended.");
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
