import { createDescriptorData } from "../thestoreCommands";
import logger from "../../Log";
export var commandPointerLockStart = function (configData, messageJson) {
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            status: messageJson.command,
        },
    };
    var element = document.querySelector("video");
    if (element instanceof HTMLVideoElement) {
        element.requestPointerLock();
        logger.debug("Pointer lock was started.");
    }
    else {
        logger.warn("No valid <video> element found.");
    }
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
