import { createDescriptorData } from "../thestoreCommands";
import logger from "../../Log";
import { normalizeToBoolean } from "../normalizeToBoolean";
export var commandSetPointerLockRelease = function (configData, messageJson, setPointerLockRelease) {
    var _a;
    var pointerLockReleaseValue = normalizeToBoolean((_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.value);
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            status: messageJson.command,
            value: pointerLockReleaseValue,
        },
    };
    setPointerLockRelease(pointerLockReleaseValue);
    logger.debug("Pointer lock release value set to: ".concat(pointerLockReleaseValue));
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
