import { createDescriptorData } from "../thestoreCommands";
import logger from "../../Log";
import { normalizeToBoolean } from "../normalizeToBoolean";
export var commandSetUsePointerLock = function (configData, messageJson, setUsePointerLock) {
    var _a;
    var usePointerLockValue = normalizeToBoolean((_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.value);
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            status: messageJson.command,
            value: usePointerLockValue,
        },
    };
    setUsePointerLock(usePointerLockValue);
    logger.debug("Use pointer lock value set to: ".concat(usePointerLockValue));
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
