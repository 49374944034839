var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { pushAbtShield } from "./abtShield";
import { Stage } from "../components/AppContext";
import { addMobileParam, getQueryBootString } from "./bootString";
import logThestore from "./logThestore";
import isDemo from "../utilities/isDemo";
import { t } from "i18next";
import { latencyTest, calculateNetworkBandwidth as calculateNetworkBandwidthInBytesPerSecond, } from "../utilities/isFastNet";
import getRedirectUrl from "../utilities/redirectUrl";
import researchEndCompleted from "../utilities/commands/researchEndCompleted";
import researchEndErrorUser from "./commands/researchEndErrorUser";
import researchEndErrorTech from "./commands/researchEndErrorTech";
import { commandPoorInternetQuality } from "./commands/poorInternetQuality";
import { commandSetUsePointerLock } from "./commands/setUsePointerLock";
import { commandSetPointerLockRelease } from "./commands/setPointerLockRelease";
import { commandPointerLockStart } from "./commands/pointerLockStart";
import { commandPointerLockEnd } from "./commands/pointerLockEnd";
import { commandSetMousePointerLock } from "./commands/setMousePointerLock";
import { config } from "dotenv";
config();
export var commandTheStore = function (configData, messageJson, stage, setStage, emitter, setInfo, setErrorInfo, setLoaderStatus, setUsePointerLock, setPointerLockRelease, receivedMessages, setReceivedMessages) { return __awaiter(void 0, void 0, Promise, function () {
    var callbackInfo, commandHash, newEvent, newReceivedMessages;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                commandHash = commandToHash(messageJson);
                newEvent = true;
                if (messageJson.attempt !== 1 && receivedMessages.includes(commandHash)) {
                    newEvent = false;
                }
                if (!(messageJson.command === "initBoot")) return [3 /*break*/, 1];
                callbackInfo = commandInitBoot(configData, stage, setStage, messageJson);
                return [3 /*break*/, 17];
            case 1:
                if (!(messageJson.command === "setUsePointerLock")) return [3 /*break*/, 2];
                callbackInfo = commandSetUsePointerLock(configData, messageJson, setUsePointerLock);
                return [3 /*break*/, 17];
            case 2:
                if (!(messageJson.command === "setPointerLockRelease")) return [3 /*break*/, 3];
                callbackInfo = commandSetPointerLockRelease(configData, messageJson, setPointerLockRelease);
                return [3 /*break*/, 17];
            case 3:
                if (!(messageJson.command === "pointerLockStart")) return [3 /*break*/, 4];
                callbackInfo = commandPointerLockStart(configData, messageJson);
                return [3 /*break*/, 17];
            case 4:
                if (!(messageJson.command === "pointerLockEnd")) return [3 /*break*/, 5];
                callbackInfo = commandPointerLockEnd(configData, messageJson);
                return [3 /*break*/, 17];
            case 5:
                if (!(messageJson.command === "setMousePointerLock")) return [3 /*break*/, 6];
                callbackInfo = commandSetMousePointerLock(configData, messageJson, setUsePointerLock);
                return [3 /*break*/, 17];
            case 6:
                if (!(messageJson.command === "ack")) return [3 /*break*/, 7];
                callbackInfo = commandAck(configData, stage, messageJson);
                return [3 /*break*/, 17];
            case 7:
                if (!(messageJson.command === "scenarioStart")) return [3 /*break*/, 8];
                callbackInfo = commandScenarioStart(configData, stage, messageJson);
                return [3 /*break*/, 17];
            case 8:
                if (!(messageJson.command === "scenarioEnd")) return [3 /*break*/, 9];
                callbackInfo = commandScenarioEnd(configData, stage, messageJson);
                return [3 /*break*/, 17];
            case 9:
                if (!(messageJson.command === "researchEnd")) return [3 /*break*/, 10];
                callbackInfo = commandResearchEnd(configData, stage, setStage, setInfo, messageJson, setErrorInfo);
                return [3 /*break*/, 17];
            case 10:
                if (!(messageJson.command === "progressFinished")) return [3 /*break*/, 11];
                callbackInfo = commandProgressFinished(configData, stage, setStage, setLoaderStatus, messageJson);
                return [3 /*break*/, 17];
            case 11:
                if (!(messageJson.command === "progressStep")) return [3 /*break*/, 12];
                callbackInfo = commandProgressStep(configData, stage, setStage, setLoaderStatus, messageJson);
                return [3 /*break*/, 17];
            case 12:
                if (!(messageJson.command === "progressError")) return [3 /*break*/, 13];
                callbackInfo = commandProgressError(configData, stage, setErrorInfo, messageJson);
                return [3 /*break*/, 17];
            case 13:
                if (!(messageJson.command === "communicationError")) return [3 /*break*/, 14];
                callbackInfo = commandCommunicationError(configData, stage, messageJson);
                return [3 /*break*/, 17];
            case 14:
                if (!(messageJson.command === "checkBandwidth")) return [3 /*break*/, 16];
                return [4 /*yield*/, commandCheckBandwidth(configData, stage, messageJson)];
            case 15:
                callbackInfo = _a.sent();
                return [3 /*break*/, 17];
            case 16:
                if (messageJson.command === "poorInternetQuality") {
                    callbackInfo = commandPoorInternetQuality(configData, stage, messageJson);
                }
                _a.label = 17;
            case 17:
                if (newEvent) {
                    if (callbackInfo === null || callbackInfo === void 0 ? void 0 : callbackInfo.abtShieldLogs) {
                        pushAbtShield(callbackInfo.abtShieldLogs);
                    }
                    if (callbackInfo === null || callbackInfo === void 0 ? void 0 : callbackInfo.thestoreLogs) {
                        logThestore(callbackInfo.thestoreLogs);
                    }
                }
                if (messageJson.command !== "communicationError") {
                    if (callbackInfo === null || callbackInfo === void 0 ? void 0 : callbackInfo.descriptorData) {
                        sendConfirmation(emitter, callbackInfo.descriptorData, messageJson);
                    }
                    if (newEvent) {
                        newReceivedMessages = __spreadArray([], receivedMessages, true);
                        newReceivedMessages.push(commandHash);
                        setReceivedMessages(newReceivedMessages);
                    }
                }
                return [2 /*return*/];
        }
    });
}); };
export var sendConfirmation = function (emitter, callbackInfo, messageJson) {
    var descriptor = {
        command: "confirm",
        confirmed: messageJson,
    };
    if (callbackInfo) {
        descriptor.callbackInfo = callbackInfo;
    }
    console.log("Answer:", JSON.stringify(descriptor));
    emitter.EmitUIInteraction(descriptor);
};
export var commandInitBoot = function (configData, stage, setStage, messageJson) {
    var _a, _b, _c, _d;
    var abtShieldLogs = [
        "send",
        "event",
        "status_message_received",
        "researchInit",
    ];
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
        },
    };
    var descriptorData = createDescriptorData(messageJson, {
        bootString: isDemo && ((_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.ts) === null || _b === void 0 ? void 0 : _b.startString)
            ? addMobileParam(configData.content.ts.startString)
            : getQueryBootString(),
        uid: (_d = (_c = configData.content) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.uid,
    });
    setStage(Stage.ResearchInit);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs, abtShieldLogs: abtShieldLogs };
};
export var commandAck = function (configData, stage, messageJson) {
    var _a, _b;
    var abtShieldLogs = ["send", "event", "status_message_received", "ack"];
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
        },
    };
    var descriptorData = createDescriptorData(messageJson, {
        uid: (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
    });
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs, abtShieldLogs: abtShieldLogs };
};
export var commandScenarioStart = function (configData, stage, messageJson) {
    var _a, _b;
    var abtShieldLogs = [
        "send",
        "event",
        "status_message_received",
        "scenarioStart",
        ((_a = messageJson === null || messageJson === void 0 ? void 0 : messageJson.params) === null || _a === void 0 ? void 0 : _a.scenarioName) || "",
    ];
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
            name: (_b = messageJson.params) === null || _b === void 0 ? void 0 : _b.scenarioName,
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs, abtShieldLogs: abtShieldLogs };
};
export var commandScenarioEnd = function (configData, stage, messageJson) {
    var _a;
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
            name: (_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.scenarioName,
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
export var commandResearchEnd = function (configData, stage, setStage, setInfo, messageJson, setErrorInfo) {
    var _a, _b, _c, _d;
    setStage(Stage.ResearchEnd);
    var redirectUrl = getRedirectUrl(configData, messageJson);
    if (isDemo) {
        if (redirectUrl === "") {
            setInfo({
                message: t("Demo zakończone."),
            });
        }
        else {
            setInfo({
                button: t("Wróć do badania"),
                buttonUrl: redirectUrl,
                message: t("Demo zakończone."),
            });
        }
        return {};
    }
    // otherwise isResearch
    var abtShieldLogs = [
        "send",
        "event",
        "status_message_received",
        messageJson.command + "-" + ((_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.status),
    ];
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: ((_b = messageJson.params) === null || _b === void 0 ? void 0 : _b.status) === "completed" ? "info" : "error",
        payload: {
            stage: stage,
            status: messageJson.command + "-" + ((_c = messageJson.params) === null || _c === void 0 ? void 0 : _c.status),
        },
    };
    switch ((_d = messageJson.params) === null || _d === void 0 ? void 0 : _d.status) {
        case "completed":
            researchEndCompleted(configData, setInfo);
            break;
        case "error_user":
            researchEndErrorUser(configData, setInfo);
            break;
        case "error_tech":
            researchEndErrorTech(configData, setInfo, setErrorInfo, stage);
            break;
    }
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs, abtShieldLogs: abtShieldLogs };
};
export var commandProgressFinished = function (configData, stage, setStage, setLoaderStatus, messageJson) {
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    setStage(Stage.ProgressFinished);
    setTimeout(function () {
        setLoaderStatus("hide");
    }, 2000);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
export var commandProgressStep = function (configData, stage, setStage, setLoaderStatus, messageJson) {
    var _a, _b, _c;
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command + "-" + ((_a = messageJson.params) === null || _a === void 0 ? void 0 : _a.percentage),
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    if (((_b = messageJson.params) === null || _b === void 0 ? void 0 : _b.percentage) === "50") {
        setStage(Stage.ProgressStep50);
    }
    setLoaderStatus(parseInt((_c = messageJson.params) === null || _c === void 0 ? void 0 : _c.percentage));
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
export var commandProgressError = function (configData, stage, setErrorInfo, messageJson) {
    var _a, _b;
    setErrorInfo({
        uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
        message: t("Wystąpił błąd techniczny. Skontaktuj się z realizatorem badania i zgłoś, że otrzymany przez Ciebie link do badania jest niepoprawny. Obecnie badanie jest wstrzymane."),
    });
    var thestoreLogs = {
        configData: configData,
        event: "status_message_received",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
export var commandCommunicationError = function (configData, stage, messageJson) {
    var thestoreLogs = {
        configData: configData,
        event: "communication_error",
        level: "error",
        payload: {
            stage: stage,
            status: messageJson.command,
            cause: messageJson.cause,
        },
    };
    return { thestoreLogs: thestoreLogs };
};
export var commandCheckBandwidth = function (configData, stage, messageJson) { return __awaiter(void 0, void 0, Promise, function () {
    var thestoreLogs, descriptorData, data, descriptorData, error_1, thestoreErrorLogs;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                thestoreLogs = {
                    configData: configData,
                    event: "status_message_received",
                    level: "info",
                    payload: {
                        stage: stage,
                        status: messageJson.command,
                    },
                };
                // BEGIN By REACT_APP_DISABLE_CHECK_BANDWIDTH=true in .env file you can disable bandwidth check
                if (process.env.REACT_APP_DISABLE_CHECK_BANDWIDTH === "true") {
                    descriptorData = createDescriptorData(messageJson, {
                        networkBandwidth: 0, // 0 means UE uses default behaviour
                    });
                    return [2 /*return*/, { descriptorData: descriptorData, thestoreLogs: thestoreLogs }];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, latencyTest()];
            case 2:
                data = _a.sent();
                descriptorData = void 0;
                if (typeof data === "boolean") {
                    descriptorData = { callback: "" };
                }
                else {
                    descriptorData = createDescriptorData(messageJson, {
                        networkBandwidth: calculateNetworkBandwidthInBytesPerSecond(data.averageLatency),
                    });
                }
                return [2 /*return*/, { descriptorData: descriptorData, thestoreLogs: thestoreLogs }];
            case 3:
                error_1 = _a.sent();
                thestoreErrorLogs = {
                    configData: configData,
                    event: "check_bandwidth_failed",
                    level: "warning",
                    payload: {
                        stage: stage,
                        error: error_1 instanceof Error ? error_1.message : String(error_1),
                    },
                };
                logThestore(thestoreErrorLogs);
                return [2 /*return*/, { descriptorData: { callback: "" }, thestoreLogs: thestoreLogs }];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var commandToHash = function (messageJson) {
    var messageToHash = {
        command: messageJson.command,
        params: messageJson.params,
    };
    return hashCode(JSON.stringify(messageToHash)).toString();
};
function hashCode(str) {
    var hash = 0;
    for (var i = 0, len = str.length; i < len; i++) {
        var chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
export var createDescriptorData = function (messageJson, data) {
    return {
        callback: messageJson.command + "Received",
        data: data,
    };
};
